<template>
  <!-- begin::Example -->
  <div class="p-5">
    <h1>Dashboard</h1>
    <p class="lead">This mobile version of NeoOpatrace&trade; application is currently under development.</p>
    <p>Please contact Opalean&trade; team for more informations.</p>
  </div>
  <!-- end::Example -->
</template>

<script>
export default {
  name: "DashboardMobile",
};
</script>
